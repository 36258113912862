import React, { useRef, useState } from "react";

import { isMobile } from "react-device-detect";

import Wave from "./component/Wave";

import "./App.css";

const RATIO_BASE = 100;
const RATIO_MULT = 80;

const ZOOM_BASE = 450;
const ZOOM_MULT = 200;

const App = () => {
  const [hover, setHover] = useState(false);
  // const [toggle, setToggle] = useState(false);
  const [rat, setRat] = useState(0);

  // Finding the middle point
  const centerXRef = useRef(window.screen.width / 2);
  const centerYRef = useRef(window.screen.height / 2);
  // Calculating the length between center
  const centerLength = useRef(
    Math.sqrt(Math.pow(centerXRef.current, 2) + Math.pow(centerYRef.current, 2))
  );

  const ratio = (isMobile ? 1.5 : 1) * RATIO_BASE + rat * RATIO_MULT;
  const zoom = (isMobile ? 0.8 : 1) * ZOOM_BASE - ZOOM_MULT * rat;

  return (
    <div
      className="App"
      style={{ position: "relative", overflow: "hidden" }}
      onMouseMove={(e) => {
        const cartesianLength = Math.sqrt(
          Math.pow(centerXRef.current - e.clientX, 2) +
            Math.pow(centerYRef.current - e.clientY, 2)
        );
        setRat((centerLength.current - cartesianLength) / centerLength.current);
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          transform: "translate(55%, 55%)",
        }}
      >
        <Wave
          zoom={zoom}
          colorToggle={isMobile || hover}
          noiseFactor={ratio}
          width={window.screen.width * 2.2}
          height={window.screen.height * 2.2}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-55%, -55%)",
        }}
      >
        <Wave
          zoom={zoom}
          colorToggle={isMobile || hover}
          noiseFactor={ratio}
          width={window.screen.width * 2.2}
          height={window.screen.height * 2.2}
        />
      </div>
      <span className="main-text">
        We are
        <br />
        <em
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          // onClick={() => setToggle(!toggle)}
          style={{
            cursor: "pointer",
            textDecorationLine: "underline",
            fontSize: "1.8em",
            margin: "0 0.15em",
          }}
        >
          Qwertyer
        </em>
        ,<br />
        and we love
        <br />
        showing
        <br />
        cool stuff
      </span>
      <a
        href="https://www.linkedin.com/in/patrick-cho/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "absolute",
          top: 10,
          left: 20,

          color: "rgba(255,255,255, 0.3)",
          fontSize: "1.2rem",
          fontWeight: 900,
        }}
      >
        @Patrick Cho
      </a>
    </div>
  );
};

export default App;
