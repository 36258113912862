/**
 * This will generate array of colors
 *
 * @param {number} n number of colors pushed to array
 */
export const generateRainbowRgbArray = (n) => {
  const center = 128;
  const width = 127;
  const frequency = (Math.PI * 2) / n;

  const array = [];

  for (var i = 0; i < n; ++i) {
    const r = Math.round(Math.sin(frequency * i + 2) * width + center);
    const g = Math.round(Math.sin(frequency * i + 0) * width + center);
    const b = Math.round(Math.sin(frequency * i + 4) * width + center);
    array.push(`rgb(${r}, ${g}, ${b})`);
  }

  return array;
};
