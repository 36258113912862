import React, { Component } from "react";
import PropTypes from "prop-types";
import { makeNoise3D } from "open-simplex-noise";
import { generateRainbowRgbArray } from "../../lib/uiUtils";

const NUM_COLORS = 100;
const COLOR_CHANGE_SPEED = 150;

/**
 * Draws a waves on the screen
 * Ref:
 */
class Wave extends Component {
  static defaultProps = {
    noiseFactor: 25,
    zoom: 200,
    colorToggle: false,
  };

  static propTypes = {
    noiseFactor: PropTypes.number,
    zoom: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    colorToggle: PropTypes.bool,
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.noiseFactor !== this.props.noiseFactor) {
  //     this.reset();
  //   }
  // }

  componentDidMount = () => {
    this.width = this.props.width;
    this.height = this.props.height;

    this.noise3D = makeNoise3D(Date.now());
    this.canvas = this.refs.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.ctx.lineJoin = "round";
    this.ctx.lineWidth = 0.6;
    this.reset();
    this.draw();

    this.randomColors = generateRainbowRgbArray(NUM_COLORS);
    window.addEventListener("resize", this.reset);
  };

  render() {
    return (
      <canvas
        ref="canvas"
        width={this.props.width}
        height={this.props.height}
      />
    );
  }

  reset = () => {
    this.ticker = 0;
    this.m = Math.min(this.width, this.height);
  };

  draw = () => {
    this.ticker += 0.01;
    requestAnimationFrame(this.draw);
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.strokeStyle = "white";
    for (let i = 10; i < this.m / 2.2 - 60; i += 15) {
      if (
        this.props.colorToggle &&
        this.randomColors &&
        this.randomColors.length > 0
      ) {
        this.ctx.strokeStyle = this.randomColors[
          (i / 10 - 1 + Math.round(this.ticker * COLOR_CHANGE_SPEED)) %
            NUM_COLORS
        ];
      }
      this.drawCircle(i);
    }
  };

  drawCircle(r) {
    this.ctx.beginPath();
    let point, x, y;
    for (let angle = 0; angle < Math.PI * 2 + 0.02; angle += 0.02) {
      point = this.calcPoint(angle >= Math.PI * 2 ? Math.PI * 2 : angle, r);
      x = point[0];
      y = point[1];
      this.ctx.lineTo(x, y);
    }
    this.ctx.stroke();
  }

  calcPoint = (angle, r) => {
    const { noiseFactor, zoom } = this.props;
    let x = Math.cos(angle) * r + this.width / 2;
    let y = Math.sin(angle) * r + this.height / 2;
    const n = this.noise3D(x / zoom, y / zoom, this.ticker * 2) * noiseFactor;
    x = Math.cos(angle) * (r + n) + this.width / 2;
    y = Math.sin(angle) * (r + n) + this.height / 2;
    return [x, y];
  };
}

export default Wave;
